import React, { useState, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ThreeScene from "./ThreeScene";

const ResetPasswordConfirm = () => {
  const { resetPasswordConfirm } = useContext(AuthContext);
  const { uid, token } = useParams();
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword1 !== newPassword2) {
      setError("Passwords do not match.");
      return;
    }
    try {
      await resetPasswordConfirm(uid, token, newPassword1, newPassword2);
      setMessage("Password has been reset successfully.");
      setTimeout(() => {
        navigate("/login");
      }, 5000); // Redirect to login after 5 seconds
    } catch (error) {
      setError("Failed to reset password.");
    }
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="lg">
        <Box my={2}>
          <Card>
            <CardContent>
              <h2>Reset Password Confirm</h2>
              <form onSubmit={handleSubmit}>
                <div>
                  <label>New Password</label>
                  <input
                    type="password"
                    value={newPassword1}
                    onChange={(e) => setNewPassword1(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    value={newPassword2}
                    onChange={(e) => setNewPassword2(e.target.value)}
                    required
                  />
                </div>
                <button type="submit">Reset Password</button>
              </form>
              {message && <div className="alert alert-primary">{message}</div>}
              {error && <div className="alert alert-danger">{error}</div>}
            </CardContent>
          </Card>
        </Box>
        <ThreeScene />
        <div className="footer">
          <p>&copy; 2024 wombol.com All rights reserved.</p>
        </div>
      </Container>
    </>
  );
};

export default ResetPasswordConfirm;

import axios from "axios";
import { API_URL } from "../config";

/*

// import { v4 as uuidv4 } from 'uuid';
const postRequest = async (url, data) => {
    try {
        const response = await axios.post(url, data);
        // Handle response data
        console.log(response.data);
  
        // Generate a unique UUID for each post log
        const postId = uuidv4();
        // Optionally store some data to localStorage
        localStorage.setItem(`lastFormResponse-${postId}`, JSON.stringify(response.data));
      } catch (error) {
        console.error('Error submitting form:', error);
  
        // Generate a unique UUID for each error log
        const errorId = uuidv4();
  
        // Store error message in localStorage with a unique key
        localStorage.setItem(`formSubmitError-${errorId}`, error.message);
      }
}
*/

export const login = async (username, password) => {
    const response = await axios.post(
      API_URL + "login/",
      { username, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.data.key) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const register = async (username, email, password) => {
  try {
    const response = await axios.post(
      API_URL + "registration/",
      {
        username: username,
        email: email,
        password1: password,
        password2: password,
      },
      {
        "Content-Type": "application/json",
      }
    );
    if (response.data.key) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return { data: response.data, error: null };
  } catch (error) {
    // Return the error message
    if (error.response && error.response.data) {
      // Return the error message from the server if available
      return { data: null, error: error.response.data };
    } else {
      // Return a generic error message
      return { data: null, error: "Registration failed. Please try again." };
    }
  }
};

export const resetPassword = (email) => {
  return axios.post(API_URL + "password/reset/", { email });
};

export const resetPasswordConfirm = (
  uid,
  token,
  new_password1,
  new_password2
) => {
  return axios.post(API_URL + "password/reset/confirm/", {
    uid,
    token,
    new_password1,
    new_password2,
  });
};

export const confirmEmail = async (setMessage, key) => {
  try {
    const response = await axios.get(
      API_URL + `registration/account-confirm-email/${key}/`
    );
    setMessage(response.data.detail);
    // Optionally, redirect or perform another action on success
  } catch (error) {
    setMessage("An error occurred. Please try again later.");
    // Optionally, handle error scenarios further if needed
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    console.log(`Token ${JSON.parse(localStorage.getItem("user")).key}`);
    const response = await axios.post(
      API_URL + "password/change/",
      { old_password: oldPassword, new_password1: newPassword, new_password2: newPassword },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${
            JSON.parse(localStorage.getItem("user")).key
          }`,
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    // Return the error message
    if (error.response && error.response.data) {
      // Return the error message from the server if available
      return { data: null, error: error.response.data };
    } else {
      // Return a generic error message
      return { data: null, error: "Password change failed. Please try again." };
    }
  }
};

// SimplePlane.js
import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
const Cube = () => {
  return (
    <mesh>
      <boxGeometry args={[2, 2, 2]} />
      <meshStandardMaterial color="black" wireframe />
    </mesh>
  );
};

const ThreeScene = () => {
  return (
    <Canvas style={{ height: '10vh' }}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <OrbitControls enableZoom={false}/>
      <Cube />
    </Canvas>
  );
};

export default ThreeScene;
import React, { useState, useContext } from "react";
import { Button } from "@mui/material";
import ConfirmationModal from "./ConfirmationModal";
import { SubscriptionContext } from "./SubscriptionContext";
import SubscriptionButton from "./SubscriptionButton";

export default function SubscriptionForm() {
  const { subscription: isSubscribed, updateSubscription } =
    useContext(SubscriptionContext);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    // Perform the action here
    handleDisableSubscription();
    setOpen(false);
  };
  const handleDisableSubscription = () => {
    updateSubscription(false);
    // Call your backend to disable the subscription
  };

  return (
    <div style={containerStyle}>
      <h3>Subscription Information</h3>
      <div>
        <label>
          Status:
          <span style={!isSubscribed ? { color: "#cc0000" } : { color: "green" }}>
            {isSubscribed ? "Active" : "Inactive"}
          </span>
        </label>
        {isSubscribed && (
          <>
            <Button type="button" onClick={handleOpen} style={{ float: "right" }}>
              Unsubscribe
            </Button>
            <ConfirmationModal
              open={open}
              onClose={handleClose}
              onConfirm={handleConfirm}
            />
          </>
        )}
      </div>
      <hr />
      {!isSubscribed && <SubscriptionButton />}
    </div>
  );
}
const containerStyle = { width: "100%", height: "100%" };

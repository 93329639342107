import React from "react";
import Navbar from "./Navbar";

const support_email = "bucklerco@gmail.com";
export default function Privacy() {
  return (
    <>
      <Navbar />
      <div style={{ height: "75px" }}></div>
      <div style={styles.container}>
        <h1 style={{ ...styles.header, ...styles.h1 }}>Privacy Policy</h1>

        <h2 style={{ ...styles.header, ...styles.h2 }}>1. Introduction</h2>
        <p style={styles.paragraph}>
          Welcome to Wombol LLC ("we," "our," or "us"). We are
          committed to protecting your privacy. This Privacy Policy explains how
          we collect, use, disclose, and safeguard your information when you
          visit our website https://wombol.com and use our online education
          platform (the "Service"). Please read this Privacy Policy carefully.
          If you do not agree with the terms of this Privacy Policy, please do
          not access the Service.
        </p>

        <h2 style={{ ...styles.header, ...styles.h2 }}>
          2. Information We Collect
        </h2>
        <p style={styles.paragraph}>
          We may collect information about you in various ways, including:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <span style={styles.highlight}>Personal Data:</span> Personally
            identifiable information, such as your name, email address, and
            other contact details, that you voluntarily provide when you
            register for an account, participate in courses, or contact us for
            support.
          </li>
          <li style={styles.listItem}>
            <span style={styles.highlight}>Usage Data:</span> Information about
            your interactions with the Service, such as the pages you visit, the
            courses you view or enroll in, and other actions you perform.
          </li>
          <li style={styles.listItem}>
            <span style={styles.highlight}>Device Data:</span> Information about
            the device you use to access the Service, including IP address,
            browser type, operating system, and device identifiers.
          </li>
          <li style={styles.listItem}>
            <span style={styles.highlight}>
              Cookies and Tracking Technologies:
            </span>{" "}
            We use cookies and similar tracking technologies to collect and
            manage personal data, provide you with a personalized experience,
            and analyze how you use the Service.
          </li>
        </ul>

        <h2 style={{ ...styles.header, ...styles.h2 }}>
          3. How We Use Your Information
        </h2>
        <p style={styles.paragraph}>
          We may use the information we collect from you in the following ways:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            To provide and maintain our Service, including managing your account
            and providing customer support.
          </li>
          <li style={styles.listItem}>
            To personalize your experience and deliver content and product
            offerings relevant to your interests.
          </li>
          <li style={styles.listItem}>
            To improve our Service based on the information and feedback we
            receive from you.
          </li>
          <li style={styles.listItem}>
            To process transactions and manage payments.
          </li>
          <li style={styles.listItem}>
            To communicate with you, including sending you updates, newsletters,
            marketing communications, and other information that may be of
            interest to you.
          </li>
          <li style={styles.listItem}>
            To monitor and analyze usage and trends to improve your experience
            with the Service.
          </li>
          <li style={styles.listItem}>
            To detect, prevent, and address technical issues and security risks.
          </li>
        </ul>

        <h2 style={{ ...styles.header, ...styles.h2 }}>
          4. How We Share Your Information
        </h2>
        <p style={styles.paragraph}>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties, except in the following circumstances:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <span style={styles.highlight}>With your consent:</span> We may
            share your information with third parties if you give us consent to
            do so.
          </li>
          <li style={styles.listItem}>
            <span style={styles.highlight}>Service Providers:</span> We may
            share your information with third-party service providers who
            perform services on our behalf, such as payment processing, data
            analysis, and email delivery.
          </li>
          <li style={styles.listItem}>
            <span style={styles.highlight}>Legal Requirements:</span> We may
            disclose your information if required to do so by law or in response
            to valid requests by public authorities (e.g., a court or government
            agency).
          </li>
          <li style={styles.listItem}>
            <span style={styles.highlight}>Business Transfers:</span> In the
            event of a merger, acquisition, or sale of all or a portion of our
            assets, your information may be transferred as part of the
            transaction.
          </li>
        </ul>

        <h2 style={{ ...styles.header, ...styles.h2 }}>5. Security</h2>
        <p style={styles.paragraph}>
          We take reasonable measures to protect your information from
          unauthorized access, use, or disclosure. However, no internet or email
          transmission is ever fully secure or error-free. Please keep this in
          mind when disclosing any personal information to us via the internet.
        </p>

        <h2 style={{ ...styles.header, ...styles.h2 }}>6. Your Rights</h2>
        <p style={styles.paragraph}>
          Depending on your location, you may have certain rights regarding your
          personal information, such as the right to access, correct, or delete
          your personal data, and the right to restrict or object to the
          processing of your data. To exercise these rights, please contact us
          at {support_email}.
        </p>

        <h2 style={{ ...styles.header, ...styles.h2 }}>
          7. Changes to This Privacy Policy
        </h2>
        <p style={styles.paragraph}>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page and
          updating the "Effective Date" above. You are advised to review this
          Privacy Policy periodically for any changes.
        </p>

        <h2 style={{ ...styles.header, ...styles.h2 }}>8. Contact Us</h2>
        <p style={styles.paragraph}>
          If you have any questions about this Privacy Policy, please contact us
          at {support_email}.
        </p>
      </div>
    </>
  );
}

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#fff",
    color: "#333",
  },
  header: {
    textTransform: "uppercase",
    borderBottom: "2px solid #333",
    paddingBottom: "5px",
  },
  h1: {
    color: "#000",
  },
  h2: {
    color: "#444",
  },
  h3: {
    color: "#666",
  },
  paragraph: {
    lineHeight: "1.6",
  },
  list: {
    listStyleType: "none",
    padding: 0,
  },
  listItem: {
    display: "flex",
    alignItems: "center",
  },
  listItemBefore: {
    content: '""',
    color: "#000",
    fontWeight: "bold",
    display: "inline-block",
    width: "1em",
    marginLeft: "-1em",
  },
  highlight: {
    color: "#000",
    fontWeight: "bold",
  },
  footer: {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#333",
    color: "#fff",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
};

import React from "react";
import Navbar from "./Navbar";

export default function Terms() {
  return (
    <>
      <Navbar />
      <div style={{ height: "75px" }}></div>
      <div style={styles.container}>
        <h1 style={{ ...styles.header, ...styles.h1 }}>Terms of Service</h1>

        <h2 style={{ ...styles.header, ...styles.h2 }}>
          1. Acceptance of Terms
        </h2>
        <p style={styles.paragraph}>
          By accessing or using Wombol (the "Service"), you agree
          to be bound by these Terms of Service ("Terms"). If you do not agree
          to these Terms, you may not use the Service. These Terms apply to all
          users of the Service, including but not limited to users who are
          instructors, students, and visitors.
        </p>

        <h2 style={{ ...styles.header, ...styles.h2 }}>
          2. Use of the Service
        </h2>
        <p style={styles.paragraph}>
          You agree to use the Service only for lawful purposes and in
          accordance with these Terms. You agree not to:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <span style={styles.highlight}>•</span> Use the Service in any way
            that violates any applicable federal, state, local, or international
            law or regulation.
          </li>
          <li style={styles.listItem}>
            <span style={styles.highlight}>•</span> Engage in any conduct that
            restricts or inhibits anyone’s use or enjoyment of the Service, or
            which, as determined by us, may harm us or users of the Service or
            expose them to liability.
          </li>
          <li style={styles.listItem}>
            <span style={styles.highlight}>•</span> Use the Service in any
            manner that could disable, overburden, damage, or impair the Service
            or interfere with any other party’s use of the Service.
          </li>
        </ul>

        <h2 style={{ ...styles.header, ...styles.h2 }}>
          3. Intellectual Property Rights
        </h2>
        <p style={styles.paragraph}>
          The Service and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof) are owned by Wombol, its licensors,
          or other providers of such material and are protected by United States
          and international copyright, trademark, patent, trade secret, and
          other intellectual property or proprietary rights laws.
        </p>

        <h2 style={{ ...styles.header, ...styles.h2 }}>
          4. User Contributions
        </h2>
        <p style={styles.paragraph}>
          The Service may contain message boards, chat rooms, personal web pages
          or profiles, forums, bulletin boards, and other interactive features
          (collectively, "Interactive Services") that allow users to post,
          submit, publish, display, or transmit to other users or other persons
          (hereinafter, "post") content or materials (collectively, "User
          Contributions") on or through the Service.
        </p>
        <p style={styles.paragraph}>
          All User Contributions must comply with the Content Standards set out
          in these Terms.
        </p>
        <p style={styles.paragraph}>
          Any User Contribution you post to the site will be considered
          non-confidential and non-proprietary. By providing any User
          Contribution on the Service, you grant us and our affiliates and
          service providers, and each of their and our respective licensees,
          successors, and assigns the right to use, reproduce, modify, perform,
          display, distribute, and otherwise disclose to third parties any such
          material for any purpose.
        </p>

        <h2 style={{ ...styles.header, ...styles.h2 }}>5. Prohibited Uses</h2>
        <p style={styles.paragraph}>
          You may use the Service only for lawful purposes and in accordance
          with these Terms. You agree not to use the Service:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <span style={styles.highlight}>•</span> In any way that violates any
            applicable federal, state, local, or international law or
            regulation.
          </li>
          <li style={styles.listItem}>
            <span style={styles.highlight}>•</span> For the purpose of
            exploiting, harming, or attempting to exploit or harm minors in any
            way by exposing them to inappropriate content or otherwise.
          </li>
          <li style={styles.listItem}>
            <span style={styles.highlight}>•</span> To send, knowingly receive,
            upload, download, use, or re-use any material that does not comply
            with the Content Standards set out in these Terms.
          </li>
        </ul>
      </div>
    </>
  );
}

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#fff",
    color: "#333",
  },
  header: {
    textTransform: "uppercase",
    borderBottom: "2px solid #333",
    paddingBottom: "5px",
  },
  h1: {
    color: "#000",
  },
  h2: {
    color: "#444",
  },
  h3: {
    color: "#666",
  },
  paragraph: {
    lineHeight: "1.6",
  },
  list: {
    listStyleType: "none",
    padding: 0,
  },
  listItem: {
    display: "flex",
    alignItems: "center",
  },
  listItemBefore: {
    content: '""',
    color: "#000",
    fontWeight: "bold",
    display: "inline-block",
    width: "1em",
    marginLeft: "-1em",
  },
  highlight: {
    color: "#000",
    fontWeight: "bold",
  },
  footer: {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#333",
    color: "#fff",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
};

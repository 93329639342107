import React, { createContext, useState, useEffect } from 'react';
import { API_DOMAIN } from "../config";

// Create the context
export const SubscriptionContext = createContext();

// Create the provider component
export const SubscriptionProvider = ({ children }) => {
    // State to store the subscription data
    const [subscription, setSubscription] = useState(null);
    const user = localStorage.getItem("user")
    useEffect(() => {
      const userKey = JSON.parse(user)?.key;
      fetch(`${API_DOMAIN}/api/check-subscription`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${userKey}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          setSubscription(data["isSubscribed"]);
        })
        .catch(() => {
          setSubscription(false);
        });
    }, [user]);
    // Function to update the subscription data
    const updateSubscription = (newSubscription) => {
        setSubscription(newSubscription);
    };

    // Value object to be provided by the context
    const contextValue = {
        subscription,
        updateSubscription,
    };

    // Render the provider with the provided value
    return (
        <SubscriptionContext.Provider value={contextValue}>
            {children}
        </SubscriptionContext.Provider>
    );
};
import React, { useState } from 'react';

export const Calculator = () => {
    const [displayValue, setDisplayValue] = useState('');
    const [history, setHistory] = useState([]);

    const handleNumberClick = (number) => {
        setDisplayValue(displayValue + number);
    };

    const handleOperatorClick = (operator) => {
        setDisplayValue(displayValue + operator);
    };

    const handleEqualsClick = () => {
        try {
            console.log(history, displayValue);
            const result = calculate(displayValue);
            setDisplayValue(result.toString());
            setHistory([...history, result]);
        } catch (error) {
            setDisplayValue('Error');
        }
    };

    const handleSquareRootClick = () => {
        handleOperatorClick("^")
        handleNumberClick(0.5)
    };

    const handleClearClick = () => {
        setDisplayValue('');
    };

    return  (
        <div>
          <input data-testid="result" type="text" value={displayValue} readOnly />
          <br />
          <button style={calculatorButton} data-testid="7" onClick={() => handleNumberClick('7')}>7</button>
          <button style={calculatorButton} data-testid="8" onClick={() => handleNumberClick('8')}>8</button>
          <button style={calculatorButton} data-testid="9" onClick={() => handleNumberClick('9')}>9</button>
          <button style={calculatorButton} data-testid="+" onClick={() => handleOperatorClick('+')}>+</button>
          <br />
          <button style={calculatorButton} data-testid="4" onClick={() => handleNumberClick('4')}>4</button>
          <button style={calculatorButton} data-testid="5" onClick={() => handleNumberClick('5')}>5</button>
          <button style={calculatorButton} data-testid="6" onClick={() => handleNumberClick('6')}>6</button>
          <button style={calculatorButton} data-testid="-" onClick={() => handleOperatorClick('-')}>-</button>
          <br />
          <button style={calculatorButton} data-testid="1" onClick={() => handleNumberClick('1')}>1</button>
          <button style={calculatorButton} data-testid="2" onClick={() => handleNumberClick('2')}>2</button>
          <button style={calculatorButton} data-testid="3" onClick={() => handleNumberClick('3')}>3</button>
          <button style={calculatorButton} data-testid="*" onClick={() => handleOperatorClick('*')}>*</button>
          <br />
          <button style={calculatorButton} data-testid="0" onClick={() => handleNumberClick('0')}>0</button>
          <button style={calculatorButton} data-testid="." onClick={() => handleOperatorClick('.')}>.</button>
          <button style={calculatorButton} data-testid="equal" onClick={() => handleEqualsClick()}>=</button>
          <button style={calculatorButton} data-testid="/" onClick={() => handleOperatorClick('/')}>/</button>
          <br />
          <button style={calculatorButton} data-testid="√" onClick={() => handleSquareRootClick()}>√</button>
          <button style={calculatorButton} data-testid="^" onClick={() => handleOperatorClick('^')}>^</button>
          <button style={{...calculatorButton, width: 75}} data-testid="clear" onClick={() => handleClearClick()}>Clear</button>
          <br />
          <button style={calculatorButton} data-testid="(" onClick={() => handleOperatorClick('(')}>(</button>
          <button style={calculatorButton} data-testid=")" onClick={() => handleOperatorClick(')')}>)</button>
        </div>    
    );
};

const calculatorButton = {
    width: '50px',
    height: '50px',
    fontSize: '20px',
    margin: '5px'
};

const tokenize = (expression) => {
    const tokens = [];
    let numberBuffer = [];
    let prevChar = null;

    for (let char of expression) {
        if (!isNaN(char) || char === '.') {
            numberBuffer.push(char);
        } else if (char === ' ') {
            continue;
        } else if (char === '-' && (prevChar === null || prevChar === '(' || prevChar === '+' || prevChar === '-' || prevChar === '*' || prevChar === '/' || prevChar === '^')) {
            // Handle negative numbers
            numberBuffer.push(char);
        } else {
            if (numberBuffer.length) {
                tokens.push(numberBuffer.join(''));
                numberBuffer = [];
            }
            tokens.push(char);
        }
        prevChar = char;
    }

    if (numberBuffer.length) {
        tokens.push(numberBuffer.join(''));
    }

    return tokens;
};


const infixToPostfix = (tokens) => {
    const precedence = {
        '+': 1,
        '-': 1,
        '*': 2,
        '/': 2,
        '^': 3,
    };

    const operators = [];
    const output = [];

    for (let token of tokens) {
        if (!isNaN(token)) {
            output.push(token);
        } else if (token === '(') {
            operators.push(token);
        } else if (token === ')') {
            while (operators.length && operators[operators.length - 1] !== '(') {
                output.push(operators.pop());
            }
            operators.pop(); // Pop the '('
        } else if (token in precedence) {
            while (
                operators.length &&
                precedence[operators[operators.length - 1]] >= precedence[token] &&
                operators[operators.length - 1] !== '('
            ) {
                output.push(operators.pop());
            }
            operators.push(token);
        }
    }

    while (operators.length) {
        output.push(operators.pop());
    }

    return output;
};

const evaluatePostfix = (tokens) => {
    const stack = [];

    for (let token of tokens) {
        if (!isNaN(token)) {
            stack.push(parseFloat(token));
        } else {
            const b = stack.pop();
            const a = stack.pop();

            switch (token) {
                case '+':
                    stack.push(a + b);
                    break;
                case '-':
                    stack.push(a - b);
                    break;
                case '*':
                    stack.push(a * b);
                    break;
                case '/':
                    stack.push(a / b);
                    break;
                case '^':
                    stack.push(Math.pow(a, b));
                    break;
                default: stack.push('ERROR');
            }
        }
    }

    return stack.pop();
};

const roundToMillionth = (value) => {
    // Round to 6 decimal places, then convert back to number
    return parseFloat(value.toFixed(6));
  };

const handleSpecialCases = (value) => {
  const rounded = roundToMillionth(value);
  // If the rounded number is an integer, return as integer
  return rounded % 1 === 0 ? Math.round(rounded) : rounded;
};

const calculate = (expression) => {
    const tokens = tokenize(expression);
    const postfix = infixToPostfix(tokens);
    const output =  evaluatePostfix(postfix);
    return handleSpecialCases(output);
};

import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordConfirm from "./components/ResetPasswordConfirm";
import Home from "./components/Home";
import About from "./components/About";
import AuthProvider from "./components/AuthContext";
import EmailConfirmation from "./components/EmailConfirmation";
import ErrorBoundary from "./components/ErrorBoundary";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import FMExam from "./components/FMExam";
import FMQuiz from "./components/FMQuiz";
import FMQuestion from "./components/FMQuestion";
import PQuestion from "./components/PQuestion";
import PExam from "./components/PExam";
import PQuiz from "./components/PQuiz";
import Careers from "./components/careers";
import AlertBox from "./components/AlertBox";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import { SubscriptionProvider } from "./components/SubscriptionContext";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './config';
import axios from "axios";
import { API_DOMAIN } from "./config";

function App() {
  useEffect(() => {
    axios.get(`${API_DOMAIN}/api/csrf_token/`)
    .then(response => {
        localStorage.setItem('csrf', response.data.csrfToken);
    })
    .catch(error => {
        console.error('Error fetching CSRF token:', error);
    });
  }, []);
  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <SubscriptionProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/reset-password-confirm/:uid/:token"
                element={<ResetPasswordConfirm />}
              />
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/about" element={<About />} />
              <Route
                path="/auth/registration/account-confirm-email/:key/"
                element={<EmailConfirmation />}
              />
              <Route path="/fm-exam" element={<FMExam />} />
              <Route path="/fm-quiz" element={<FMQuiz />} />
              <Route path="/p-exam" element={<PExam />} />
              <Route path="/p-quiz" element={<PQuiz />} />
              <Route path="/fm-question" element={<FMQuestion />} />
              <Route path="/p-question" element={<PQuestion />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="*" element={<Home />} />
            </Routes>
            <AlertBox />
          </SubscriptionProvider>
          </GoogleOAuthProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;

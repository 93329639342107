import React, { useContext, useState } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ThreeScene from "./ThreeScene";
import { commonTextBoxStyle } from "../styles/TextFieldStyles";
import { TextField, Button } from "@mui/material";
import Footer from "./Footer";
import GoogleLoginButton from './GoogleLoginButton'

const Login = () => {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let output = await login(username, password);
      if (!output) {
        navigate("/dashboard");
      } else {
        setError("Login failed");
      }
    } catch (error) {
      setError("Login failed", error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (username === "" || password === "") {
      setError("Please fill in all fields");
      return;
    } else {
      setError(null);
      handleSubmit(e);
    }
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="lg">
        <Box
          my={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              display: "flex",
              minWidth: 310,
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardContent>
              <h2>Login</h2>
              <hr />
              <br />
              <div>
                  <TextField
                    required
                    label="Username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    sx={commonTextBoxStyle}
                  />
              </div>
              <div>
                  <TextField
                    required
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={commonTextBoxStyle}
                  />
              </div>
              {error && <div className="alert alert-danger">{error}</div>}

              <div style={{ paddingTop: 5 }}>
                <button
                  type="submit"
                  style={{ color: "black", width: "100%", marginBottom: 15}}
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>

              <div style={{ paddingTop: 5 }}>
                <GoogleLoginButton />
              </div>

              <div style={{ paddingTop: 40 }}>
                <Button
                  type="submit"
                  style={{ color: "black" }}
                  onClick={() => navigate("/reset-password")}
                >
                  Forgot Password
                </Button>
              </div>
            </CardContent>
          </Card>
        </Box>
        <ThreeScene />
        <Footer />
      </Container>
    </>
  );
};

export default Login;

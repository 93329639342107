import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { API_URL } from "../config";
import { useNavigate } from "react-router-dom";

const GoogleLoginButton = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const handleSuccess = async (response) => {
    const { credential } = response;
    try {
      const res = await axios.get(`${API_URL}google-login/`, {
        params: { token: credential },
      });
      localStorage.setItem("user", JSON.stringify(res.data));
      navigate("/dashboard");
    } catch (error) {
      setError("Google Login Failed, please try again.");
      console.error("Login error:", error);
    }
  };

  const handleFailure = (error) => {
    console.error("Login failed:", error);
  };

  return (
    <>
      <GoogleLogin
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        useOneTap
      />

      {error && <div style={{ color: "#333", padding: 12 }}>{error}</div>}
    </>
  );
};

export default GoogleLoginButton;

import React, { useContext, useState } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ThreeScene from "./ThreeScene";
import { TextField } from "@mui/material";
import { commonTextBoxStyle } from "../styles/TextFieldStyles";
import Footer from "./Footer";

const Register = () => {
  const { register, login } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let output = await register(username, email, password);
      console.log(output);
      if (!output) {
        let output = await login(username, password);
        if (!output) {
          navigate("/dashboard");
        } else {
          setError("Login failed");
        }
      } else {
        setError("Registration failed");
      }
    } catch (error) {
      setError("Registration failed", error);
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    } else if (
      username === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      setError("Please fill in all fields");
      return;
    } else {
      setError(null);
      handleSubmit(e);
    }
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="lg">
        <Box
          my={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              display: "flex",
              minWidth: 310,
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardContent>
              <h2>Register</h2>
              <hr />
              <br />
              <div>
                <TextField
                  required
                  label="Username"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  sx={commonTextBoxStyle}
                />
              </div>
              <div>
                <TextField
                  required
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={commonTextBoxStyle}
                />
              </div>
              <div>
                <TextField
                  required
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={commonTextBoxStyle}
                />
              </div>
              <div>
                <TextField
                  required
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  sx={commonTextBoxStyle}
                />
              </div>
              
              {error && <div className="alert alert-danger">{error}</div>}
              <div style={{ paddingTop: 5 }}>
                <button
                  type="submit"
                  style={{ color: "black", width: "100%", marginBottom: 15}}
                  onClick={handleRegistration}
                >
                  Register
                </button>
              </div>
            </CardContent>
          </Card>
        </Box>
        <ThreeScene />
        <Footer />
      </Container>
    </>
  );
};

export default Register;

import React, { useCallback, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Question from "./Question";
import axios from "axios";
import { API_DOMAIN } from "../config";
import { shuffle } from "./Math";
import Timer from "./Timer";

export default function PExam() {
  const [pageState, setPageState] = useState({questionCounter: 0, loading: false, error: null})
  const {loading, error} = pageState;
  const answerHandler = useCallback((question, answer) => {
      setPageState({...pageState, loading: true});
      axios.post(
        `${API_DOMAIN}/api/answer-test-question/`,
        {
          testType: "ProbabilityExam",
          question: question.id,
          selectedAnswer: answer,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.key}`,
            'X-CSRFToken': localStorage.getItem('csrftoken'),
          },
        }
      ).then((response) => {
        const data = response.data;
        if (data.is_correct) {
          setPageState({...pageState, success: true});
          axios.get(API_DOMAIN + "/api/test-questions/").then((response) => {
            const question = response.data;
            setQuestionData({
              questionType: 'Probability Exam',
              questionText: question.question_text,
              questionImage: question.question_image,
              answers: shuffle([
                question.correct_answer,
                question.incorrect_answer_1,
                question.incorrect_answer_2,
                question.incorrect_answer_3,
              ]),
              checkAnswer: (answer) => answerHandler(question, answer),
            });
          }).catch(() => {
            setPageState({...pageState, error: "Sorry!! Failed to answer question due to server error. Please try refreshing the page."})
          })
          // TODO: ADD REFRESH QUESTION FUNCTION HERE.
          // - get TestQuestion data
          // - update questionData
        } else {
          setPageState({showGuide: true, loading: false, error: null})
        }
      }).catch(() => {
        setPageState({...pageState, error: "Sorry!! Failed to answer question due to server error. Please try refreshing the page."})
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [questionData, setQuestionData] = useState(null);

  useEffect(() => {
    axios.get(API_DOMAIN + "/api/questions/").then((response) => {
      const question = response.data[0];
      setQuestionData({
        questionType: 'Probability Exam',
        questionText: question.question_text,
        questionImage: question.question_image,
        answers: shuffle([
          question.correct_answer,
          question.incorrect_answer_1,
          question.incorrect_answer_2,
          question.incorrect_answer_3,
        ]),
        checkAnswer: (answer) => answerHandler(question, answer),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Navbar />
      <h2 style={{ textAlign: "center" }}>Probability Exam</h2>
      <div className="row">
        <div style={{margin: "auto"}}>
          {/** 
           * We need to finish the test if the time runs out. 
           * Create a context with questions and timer in order to load the finished page.
           * */}
          <Timer hasButtons={false} />
        </div>
      </div>
      <hr />
      <div className="row" style={{ paddingLeft: 10 }}>
        <Question question={questionData} loading={loading} progress={pageState?.questionCounter}/>
        {error && <div className="alert alert-danger" style={{margin: "auto", marginTop: 20}}>{error}</div>}
      </div>
    </div>
  );
}

import React, { Component } from "react";

class QuestionButton extends Component {
  state = {};
  render() {

    return (
      <div style={containerStyle}>
      <button
        className="btn btn-primary"
        style={buttonStyle}
        onClick={() => this.props.check_answer(this.props.answer)}
      >
        {this.props.answer}
      </button>
    </div>
    );
  }
}

export default QuestionButton;

const containerStyle = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box"
};

const buttonStyle = {
  padding: "5px 10px",
  margin: "10px",
  backgroundColor: "#007bff",
  borderColor: "#007bff",
  color: "#fff",
  fontSize: "16px",
  borderRadius: "4px",
  cursor: "pointer",
  transition: "background-color 0.3s, transform 0.2s",
};
import React, { useState, useEffect } from "react";
import { Card } from '@mui/material'


function getColorBasedOnTime(time) {
  const { hours, minutes } = time;
  
  // Total minutes remaining
  const totalMinutes = (hours * 60) + minutes;
  
  // Maximum possible minutes in a day (assuming 24-hour format)
  const maxMinutes = 24 * 60;
  
  // Calculate the normalized time remaining (from 1 to 0)
  const normalizedTime = totalMinutes / maxMinutes;
  
  // Calculate the red and green components
  const redComponent = Math.min(255, Math.floor((1 - normalizedTime) * 255));
  const greenComponent = Math.min(255, Math.floor(normalizedTime * 255));
  
  // Return the RGB color string
  return `rgb(${255-redComponent}, ${greenComponent}, 0)`;
}

function Timer({hasButtons, timeStart={ hours: 2, minutes: 59, seconds: 59}}) {
  const [time, setTime] = useState(timeStart);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
      setTime((prevTime) => {
        const { hours, minutes, seconds } = prevTime;
        if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
          clearInterval(interval);
          return { hours: 0, minutes: 0, seconds: 0 };
          } else {
          return { hours: hours - 1, minutes: 59, seconds: 59 };
          }
        } else {
          return { ...prevTime, minutes: minutes - 1, seconds: 59 };
        }
        } else {
        return { ...prevTime, seconds: seconds - 1 };
        }
      });
      }, 1000);
    } else if (!isActive) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive]);

  const toggle = () => {
    setIsActive(!isActive);
  };

  const reset = () => {
    setTime({ hours: 3, minutes: 0 });
    setIsActive(false);
  };

  return (
    <div style={{textAlign: "center"}}>
      <Card style={{padding: 15, backgroundColor: getColorBasedOnTime(time), color: "white"}}>
        <div className="time" style={{color: "white", fontWeight: "bold", lineHeight: "1", fontFamily: "monospace"}}>
          {time.hours.toString().padStart(2, '0')}:{String(time.minutes).padStart(2, '0')}:{String(time.seconds).padStart(2, '0')}
        </div>
        {hasButtons && (
          <div className="row">
            <button
              className={`button button-primary button-primary-${
                isActive ? "active" : "inactive"
              }`}
              onClick={toggle}
            >
              {isActive ? "Pause" : "Start"}
            </button>
            <button className="button" onClick={reset}>
              Reset
            </button>
          </div>
        )}
      </Card>
    </div>
  );
}

export default Timer;

import React, { useContext, useEffect } from "react";
import Navbar from "./Navbar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import SubscriptionForm from './SubscriptionForm'
import PasswordForm from './PasswordForm'
import { AuthContext } from "./AuthContext";

export default function Profile() {
  const {handleAccess} = useContext(AuthContext);
  useEffect(() => {
    handleAccess();
  }, [handleAccess])
  return (
    <>
      <Navbar />
      <Container
        maxWidth="lg"
        sx={{
          minHeight: "100vh", // Set the height to 100% of the viewport height
        }}
      >
        <Box
          my={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              display: "flex",
              minWidth: 310,
              maxWidth: 700,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardContent>
              <div>
                <h1>Profile</h1>
              </div>
              {/** Display user's profile information */}
              {/** alert them that their email isnt validated yet and how they wont be able to change or reset password */}
              {/** Make change password and manage payment subscription two tabs */}
              
              
              <Tabs orientation="horizontal" size="lg" defaultValue={0}>
                <TabList>
                  <Tab
                    variant="outlined"
                    color="neutral"
                  >
                    Password Manager
                  </Tab>
                  <Tab
                    variant="outlined"
                    color="neutral"
                  >
                    Subscription Manager 
                  </Tab>
                </TabList>
                <TabPanel value={0}>
                  <PasswordForm />
                </TabPanel>
                <TabPanel value={1}>
                  <SubscriptionForm />
                </TabPanel>
              </Tabs>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
}

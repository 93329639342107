import React from "react";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { changePassword } from "./AuthService";

export default function PasswordForm() {
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState("");
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let output = await changePassword(oldPassword, newPassword);
      console.log(output);
      if (!!output.error) {
        setError(output.error);
      } else {
        navigate("/dashboard?msg=Password changed successfully");
      }
    } catch (error) {
      setError("Login failed", error);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    //
    if (newPassword !== newPasswordConfirm) {
      setError("Passwords do not match!");
      return;
    } else if (newPassword.length < 8) {
      setError("Password must be at least 8 characters long!");
      return;
    } else if (newPassword === oldPassword) {
      setError("New password cannot be the same as the old password!");
      return;
    } else if (newPassword === "" || oldPassword === "" || newPasswordConfirm === "") {
      setError("Please fill out all fields!");
      return;
    } else {
      setError(null);
      handleSubmit(e);
    }
  };
  return (
    <>
      <h3>Change Password</h3>
      <hr />
      <form onSubmit={handleSubmit}>
        <div>
          <TextField
            required
            label="Old Password"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            style={{ width: "100%", marginBottom: 15}}
          />
        </div>
        <div>
          <TextField
            required
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={{ width: "100%", marginBottom: 15}}
          />
        </div>
        <div>
          <TextField
            required
            label="Confirm Password"
            type="password"
            value={newPasswordConfirm}
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
            style={{ width: "100%", marginBottom: 15}}
          />
        </div>
        {error && <div style={{ color: "#333" }}>{error}</div>}
        <br />
        <button
          type="submit"
          style={{ color: "black", width: "100%", marginBottom: 15}}
          onClick={handleChangePassword}
        >
          Change Password
        </button>
      </form>
    </>
  );
}

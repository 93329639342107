import React, { useState } from "react";
import { Modal, Box, Button, Typography, TextField } from "@mui/material";
import Navbar from "./Navbar";
import axios from "axios";

const jobList = [
  {
    id: "qa-engineer",
    title: "QA Engineer",
    description:
      "We are looking for a skilled QA Engineer to join our team. As a QA Engineer, you will be responsible for ensuring the quality of our software products through rigorous testing and bug reporting. You will work closely with our development team to identify and resolve any issues, and contribute to the overall improvement of our software development process.",
    responsibilities: [
      "Develop and execute test plans and test cases",
      "Identify, document, and track software defects",
      "Collaborate with developers to resolve issues",
      "Participate in code reviews and provide feedback",
      "Continuously improve testing processes",
    ],
    qualifications: [
      "Bachelor's degree in Computer Science or related field",
      "2+ years of experience in software testing",
      "Strong knowledge of QA methodologies and tools",
      "Excellent analytical and problem-solving skills",
      "Good communication and teamwork skills",
    ],
  },
  {
    id: "statistician",
    title: "Statistician",
    description:
      "We are seeking a talented Statistician to join our consulting firm. As a Statistician, you will be responsible for analyzing and interpreting data to provide valuable insights and recommendations to our clients. You will work closely with our clients to understand their needs, design appropriate statistical models, and present your findings in a clear and concise manner. Strong analytical and communication skills are essential for this role.",
    responsibilities: [
      "Analyze and interpret complex data sets",
      "Develop statistical models to solve business problems",
      "Prepare reports and presentations of findings",
      "Collaborate with clients to understand their needs",
      "Stay updated with the latest statistical techniques and tools",
    ],
    qualifications: [
      "Bachelor's degree in Statistics, Mathematics, or related field",
      "2+ years of experience in statistical analysis",
      "Proficiency with statistical software (e.g., R, Python)",
      "Excellent analytical and critical thinking skills",
      "Strong communication and presentation skills",
    ],
  },
];

const Careers = () => {
  const [open, setOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const handleOpen = (jobId) => {
    const job = jobList.find((job) => job.id === jobId);
    setSelectedJob(job);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedJob(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("job", selectedJob.id);
    formData.append("name", event.target[0].value);
    formData.append("email", event.target[1].value);
    formData.append("resume", event.target[2].files[0]);

    axios.post("/api/careers/apply", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
    .then((response) => {
        // Handle success
        console.log(response.data);
    })
    .catch((error) => {
        // Handle error
        console.error(error);
    });
    // Add success alert box.
    handleClose();
  };

  return (
    <>
      <Navbar />
      <div style={styles.container}>
        <h1 style={styles.header}>Join Our Team</h1>

        {jobList.map((job) => (
          <section key={job.id} style={styles.section}>
            <h2 style={styles.jobTitle}>{job.title}</h2>
            <p style={styles.description}>{job.description}</p>
            <h3 style={styles.subheader}>Responsibilities:</h3>
            <ul style={styles.list}>
              {job.responsibilities.map((responsibility, index) => (
                <li key={index}>{responsibility}</li>
              ))}
            </ul>
            <h3 style={styles.subheader}>Qualifications:</h3>
            <ul style={styles.list}>
              {job.qualifications.map((qualification, index) => (
                <li key={index}>{qualification}</li>
              ))}
            </ul>
            <Button
              style={styles.applyButton}
              onClick={() => handleOpen(job.id)}
            >
              Apply Now
            </Button>
          </section>
        ))}

        <section style={styles.benefitsSection}>
          <h2 style={styles.header}>Why Join Us?</h2>
          <ul style={styles.list}>
            <li>Competitive salary and benefits</li>
            <li>Opportunity for career growth</li>
            <li>Flexible working hours</li>
            <li>Collaborative and inclusive work environment</li>
            <li>Continuous learning and development</li>
          </ul>
        </section>

        <section style={styles.contactSection}>
          <h2 style={styles.header}>How to Apply</h2>
          <p style={styles.description}>
            <div>If you want to add value in a different manner,</div>
            Please send your resume and a cover letter to{" "}
            <a href="mailto:careers@ourcompany.com">careers@ourcompany.com</a>.
          </p>
        </section>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="apply-modal-title"
          aria-describedby="apply-modal-description"
        >
          <Box sx={styles.modalBox}>
            <Typography id="apply-modal-title" variant="h6" component="h2">
              Apply for {selectedJob ? selectedJob.title : ""}
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField required label="Name" fullWidth margin="normal" />
              <TextField
                required
                label="Email"
                type="email"
                fullWidth
                margin="normal"
              />
              <TextField
                required
                label="Resume"
                type="file"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                Submit Application
              </Button>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  },
  header: {
    textAlign: "center",
    color: "#333",
  },
  section: {
    marginBottom: "40px",
  },
  jobTitle: {
    fontSize: "24px",
    color: "#444",
  },
  description: {
    fontSize: "16px",
    color: "#666",
    lineHeight: "1.6",
  },
  subheader: {
    fontSize: "20px",
    color: "#555",
    marginTop: "20px",
  },
  list: {
    listStyleType: "disc",
    paddingLeft: "20px",
  },
  applyButton: {
    backgroundColor: "#007BFF",
    color: "#FFF",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    marginTop: "20px",
  },
  benefitsSection: {
    backgroundColor: "#F9F9F9",
    padding: "20px",
    borderRadius: "8px",
  },
  contactSection: {
    textAlign: "center",
    marginTop: "40px",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  },
};

export default Careers;

import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ThreeScene from "./ThreeScene";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.getItem('user') && navigate("/dashboard");
  }, [navigate]);
  
  return (
    <>
      <Navbar />
      <Container maxWidth="lg">
        <Box my={2}>
          <Card>
            <CardContent>
              <Typography variant="h3" component="h3">
                Pass Your Actuary Exam
              </Typography>
              <hr />
              <Box my={2}>
                <section id="why-choose-us">
                  <h2>Why Choose Us?</h2>
                  <ul>
                    <li>
                      <strong>Daily Free Problems:</strong> Keep your skills
                      sharp with one free problem every day. Challenge yourself
                      and stay motivated with new content regularly.
                    </li>
                    <li>
                      <strong>Unlimited Access:</strong> Upgrade to a paid plan
                      and enjoy unlimited problems, tailored to your learning
                      needs. Dive deeper into subjects that matter to you.
                    </li>
                    <li>
                      <strong>Quality Content:</strong> Experience high-quality
                      problems crafted by experts. Our content is designed to
                      challenge and inspire, ensuring you get the best learning
                      experience.
                    </li>
                  </ul>
                </section>
                <section id="start-learning">
                  <h2>Start Learning Today</h2>
                  <ol>
                    <li>
                      <strong>Create a Free Account:</strong> Sign up for free
                      and start solving one problem per day.
                    </li>
                    <li>
                      <strong>Explore Our Content:</strong> Get a taste of what
                      we offer and see how we can help you achieve your learning
                      goals.
                    </li>
                    <li>
                      <strong>Upgrade for Unlimited Access:</strong> Unlock the
                      full potential of our platform with unlimited problems,
                      premium content, and more.
                    </li>
                  </ol>

                  <button onClick={()=> window.location.href="/register"} className="cta-button">
                    Sign Up Now
                  </button>
                </section>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <ThreeScene />
        <Footer />
      </Container>
    </>
  );
};

export default Home;

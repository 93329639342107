import React, { useCallback, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Question from "./Question";
import axios from "axios";
import { API_DOMAIN } from "../config";
import { shuffle } from "./Math";
import { useNavigate } from "react-router-dom";
import GuideModal from "./GuideModal";

export default function PQuestion() {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    showGuide: false,
    loading: false,
    error: null,
    progress: 0,
  });
  const { loading, error, showGuide, progress } = pageState;

  const answerHandler = useCallback(
    (question, answer) => {
      setPageState({ ...pageState, loading: true });
      axios
        .post(
          `${API_DOMAIN}/api/answer/`,
          {
            question: question.id,
            selected_answer: answer,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${
                JSON.parse(localStorage.getItem("user"))?.key
              }`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (data.is_correct) {
            navigate("/?msg=Success! You've correctly answered the question.");
          } else {
            setPageState({ showGuide: true, loading: false, error: null });
          }
        })
        .catch((error) => {
          console.log(error);
          setPageState({
            ...pageState,
            error:
              "Sorry!! Failed to answer question due to server error. Please try again later.",
          });
        });
    },
    [pageState, navigate]
  );
  const [questionData, setQuestionData] = useState(null);

  useEffect(() => {
    axios.get(API_DOMAIN + "/api/questions/").then((response) => {
      const question = response.data[0];
      setQuestionData({
        questionType: question.category,
        questionText: question.question_text,
        questionImage: question.question_image,
        answers: shuffle([
          question.correct_answer,
          question.incorrect_answer_1,
          question.incorrect_answer_2,
          question.incorrect_answer_3,
        ]),
        checkAnswer: (answer) => answerHandler(question, answer),
      });
    });
    axios
      .get(
        `${API_DOMAIN}/api/history-progress/?token=${
          JSON.parse(localStorage.getItem("user"))?.key
        }`,
        {
          headers: {
            Authorization: `Token ${
              JSON.parse(localStorage.getItem("user"))?.key
            }`,
          },
        }
      )
      .then((response) => {
        setPageState({ ...setPageState, progress: response.data.progress });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Navbar />
      <div style={{ height: "90vh", width: "90vw" }}>
        <h2 style={{ textAlign: "center" }}>Probability Question</h2>
        <hr />
        <div className="row" style={{ paddingLeft: 10 }}>
          {error && (
            <div
              className="alert alert-danger"
              style={{ margin: "auto", marginTop: 20 }}
            >
              {error}
            </div>
          )}
          <Question
            question={questionData}
            loading={loading}
            progress={progress}
          />
        </div>
      </div>
      {showGuide && <GuideModal />}
    </div>
  );
}

// SubscriptionButton.js
import React, { useContext } from "react";
import { SubscriptionContext } from './SubscriptionContext';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { API_DOMAIN } from "../config";
import axios from "axios";

const initialOptions = {
    "client-id": "AZBH1ZIvicXfWCHEUWWeId1GEyhR4iHSbaPHwI52XlxDDNaSumxQZCvS6oCwaov8ZVTPoCKobXiaZAcg",
    currency: "USD",
    vault: true,
    intent: 'subscription',
};

const SubscriptionButton = () => {
    const { updateSubscription } = useContext(SubscriptionContext);
    const createSubscription = (data, actions) => {
        return actions.subscription.create({
            'plan_id': 'P-4U239665LN280171KMZZEVXQ',
        });
    };

    const onApprove = (data, actions) => {
        updateSubscription(true);
        axios.put(`${API_DOMAIN}payments/activate-subscription/`, { status: 'active' })
    };

    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                createSubscription={createSubscription}
                onApprove={onApprove}
                style={{ layout: 'vertical' }}
            />
        </PayPalScriptProvider>
    );
};

export default SubscriptionButton;
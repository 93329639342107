import React from 'react';
import Navbar from './Navbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const Home = () => {
    return (
        <>
            <Navbar />
            <Container maxWidth="lg">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        With Wombol you can prepare for exams or simply increase your human capital in business!
                    </Typography>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Society of Actuaries P Exam!
                            </Typography>
                            <header>
                                <h1>Welcome to [Your Learning Platform Name]</h1>
                            </header>
                            <main>
                                <section id="why-choose-us">
                                    <h2>Why Choose Us?</h2>
                                    <ul>
                                        <li><strong>Daily Free Problems:</strong> Keep your skills sharp with one free problem every day. Challenge yourself and stay motivated with new content regularly.</li>
                                        <li><strong>Unlimited Access:</strong> Upgrade to a paid plan and enjoy unlimited problems, tailored to your learning needs. Dive deeper into subjects that matter to you.</li>
                                        <li><strong>Quality Content:</strong> Experience high-quality problems crafted by experts. Our content is designed to challenge and inspire, ensuring you get the best learning experience.</li>
                                        <li><strong>Interactive Community:</strong> Join a vibrant community of learners. Share insights, discuss problems, and grow together.</li>
                                    </ul>
                                </section>
                                <section id="start-learning">
                                    <h2>Start Learning Today</h2>
                                    <ol>
                                        <li><strong>Create a Free Account:</strong> Sign up for free and start solving one problem per day.</li>
                                        <li><strong>Explore Our Content:</strong> Get a taste of what we offer and see how we can help you achieve your learning goals.</li>
                                        <li><strong>Upgrade for Unlimited Access:</strong> Unlock the full potential of our platform with unlimited problems, premium content, and more.</li>
                                    </ol>
                                    <a href="/register" class="cta-button">Sign Up Now</a>
                                </section>
                            </main>
                            <footer>
                                <p>&copy; 2024 [Your Learning Platform Name]. All rights reserved.</p>
                            </footer>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        </>
    );
};

export default Home;
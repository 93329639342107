import React, { useCallback, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function GuideModal(open) {
  const [guideInfo, setGuideInfo] = React.useState(null);
  const navigate = useNavigate();
  const queryGuide = useCallback(async () => {
    const response = await axios.post(``);
    return response.data;
  }, []);
  const handleClose = () => {
    return () => {
        navigate("/");
    }
  };
  useEffect(() => {
    const guideQuery = queryGuide();
    setGuideInfo({
        title: guideQuery.title,
        content: guideQuery.content,
    });
  }, [queryGuide]);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{guideInfo.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {guideInfo.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// EmailConfirmation.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { confirmEmail } from './AuthService';

const EmailConfirmation = () => {
  const { key } = useParams();
  const [message, setMessage] = useState('');

  useEffect(() => {
    confirmEmail(setMessage, key);
  }, [key]);

  return (
    <div>
      <h1>Email Confirmation</h1>
      <p>{message}</p>
    </div>
  );
};

export default EmailConfirmation;
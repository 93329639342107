import React, { createContext, useState, useEffect } from 'react';
import { login, logout, register, resetPassword, resetPasswordConfirm } from './AuthService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_DOMAIN } from '../config';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const navigate = useNavigate();
    useEffect(() => {
      (user?.key && window.location.href === "/login") && navigate("/dashboard");
    }, [user, navigate]);

    const handleLogin = async (username, password) => {
        const response = await login(username, password);
        setUser(response);
    };

    const handleLogout = () => {
        logout();
        setUser(null);
    };

    const handleRegister = async (username, email, password) => {
        await register(username, email, password);
    };

    const handleResetPassword = async (email) => {
        await resetPassword(email);
    };

    const handleResetPasswordConfirm = async (uid, token, new_password1, new_password2) => {
        await resetPasswordConfirm(uid, token, new_password1, new_password2);
    };

    const isAuthenticated = () => JSON.parse(localStorage.getItem('user'))

    const handleAccess = () => {
        if (!isAuthenticated()) {
          navigate("/login");
        }
    }
    const gatherProfile = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        try {
            const response = await axios.get(`${API_DOMAIN}/api/profile/?token=${user?.key}`, {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Token ${user?.key}`
                },
            });
            return response.data;
        } catch (error) {
            return {
                isEmailValidated: false,
                isSubscribed: false,
                hasDepletedQuestions: false
            };
        }
    };
    

    return (
        <AuthContext.Provider value={{
            user,
            gatherProfile,
            isAuthenticated,
            login: handleLogin,
            logout: handleLogout,
            register: handleRegister,
            resetPassword: handleResetPassword,
            resetPasswordConfirm: handleResetPasswordConfirm,
            handleAccess: handleAccess
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

import React, { useState, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ThreeScene from "./ThreeScene";
import { TextField, Button } from "@mui/material";

const ResetPassword = () => {
  const { resetPassword } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(email);
      setMessage(
        "Password reset email sent successfully. Please check your email."
      );
      setTimeout(() => {
        navigate("/login");
      }, 5000); // Redirect to login after 5 seconds
    } catch (error) {
      setError("Failed to send password reset email.");
    }
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="lg">
      <Box
          my={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        <Card
          sx={{
            display: "flex",
            minWidth: 310,
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
            <CardContent>
              <h2>Reset Password</h2>
              <form onSubmit={handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <Button type="submit">Send Reset Email</Button>
              </form>
              {message && <div className="alert alert-primary">{message}</div>}
              {error && <div className="alert alert-danger">{error}</div>}
            </CardContent>
          </Card>
        </Box>
        <ThreeScene />
        <div className="footer">
          <p>&copy; 2024 wombol.com All rights reserved.</p>
        </div>
      </Container>
    </>
  );
};

export default ResetPassword;

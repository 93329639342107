import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import Edit from "@mui/icons-material/Edit";
import useIsMobile from "./useIsMobile";
import AccountCircle from "@mui/icons-material/AccountCircle";

const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  color: "#333", // Set the text color to match
}));

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#ffffff", // Set the background color to white
  color: "#333", // Set the text color to match
}));

const Navbar = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const isMobile = useIsMobile();

  const navigate = useNavigate();
  return (
    <CustomAppBar position="static" style={{ paddingTop: 0, height: 50 }}>
      <Toolbar>
        <Title
          variant="h6"
          style={{
            fontWeight: 900, // Extra bold text
            textAlign: "center",
            textTransform: "uppercase", // Uppercase text for a sharp look
            letterSpacing: "2px", // Extra spacing for the techy effect
            fontFamily: '"Roboto Condensed", sans-serif', // Condensed font for a racecar feel
            display: "inline",
          }}
          onClick={() => navigate(!isAuthenticated() ? "/" : "/dashboard")}
        >
          Wombol.com
        </Title>
        {
          isAuthenticated() ? (
            <>
            <Button
            color="inherit"
            style={{ color: "black" }}
            onClick={() => navigate("/dashboard")}
          >
            {!isMobile && "Practice"}&nbsp;<Edit />
          </Button>
          <Button
            color="inherit"
            style={{ color: "black" }}
            onClick={() => navigate("/profile")}
          >
            {!isMobile && "Profile"}&nbsp;<AccountCircle />
          </Button>
          <Button
            color="inherit"
            style={{ color: "black" }}
            onClick={() => 
              {
                logout();
                navigate("/")
              }}
          >
            Logout
          </Button>
            </>
          ) : (
            <>
          <Button
            color="inherit"
            style={{ color: "black" }}
            onClick={() => navigate("/register")}
          >
            Register
          </Button>
          <Button
            color="inherit"
            style={{ color: "black" }}
            onClick={() => navigate("/login")}
          >
            Login
          </Button>
          </>
          )
      }
      </Toolbar>
    </CustomAppBar>
  );
};

export default Navbar;

import React from "react";
import wombol_logo from "../images/wombol_logo.png";

export default function Footer() {
  return (
    <div className="footer">
      <p style={{color: '#000'}}>
      <img src={wombol_logo} height={35} width={35} style={{ paddingTop: 0}} alt="" />
      &nbsp;&nbsp;&copy; 2024 wombol.com All rights reserved.
      </p>
      <a href="/privacy" style={link}>Privacy Policy</a><br />
      <a href="/terms" style={link}>Terms of Service</a><br />
      <a href="/careers" style={link}>Careers</a>
    </div>
  );
}

const link = {
    color: '#000',
    textDecoration: 'none',
    cursor: 'pointer'
  }
import React from "react";
import { Alert } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function AlertBox() {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const msg = queryParams.get("msg");
    if (msg) {
      setMessage(decodeURIComponent(msg));
    }
  }, [location, navigate]);

  if (message) {
    return (
      <div style={{ position: "absolute", bottom: 0, justifyContent: "center" }}>
        <Alert
          color="success"
          variant="soft"
          sx={{
            backgroundColor: "green",
            color: 'white',
            '& .MuiAlert-message': {
              color: 'white !important',
            },
          }}
        >
          {message}
        </Alert>
      </div>
    );
  } else {
    return null;
  }
}

import React, { useState } from "react";
import QuestionButton from "./QuestionButton";
import { ReactPainter } from "react-painter";
import { v4 as uuidv4 } from "uuid";
import useWindowDimensions from "../hooks/useWindowDimensions";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
import { Calculator } from "./WombolInstruments";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CalculateIcon from "@mui/icons-material/Calculate";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ClearIcon from "@mui/icons-material/Clear";
import { Skeleton } from "@mui/material";

export default function Question(question) {
  const { width, height } = useWindowDimensions();
  const [showCanvas, setShowCanvas] = useState(true);
  const createNewUUID = () => uuidv4();
  const [canvasID, setCanvasID] = useState(createNewUUID());
  const clearCanvas = () => {
    setCanvasID(createNewUUID());
  };
  console.log(question)
  const value = question.progress;

  const canvasObject = () => {
    return (
      <div>
        <ReactPainter
          key={canvasID} // Use UUID as the key to force re-render
          id={canvasID} // Ensure the id is also a string
          height={height / 2}
          width={width / 2}
          onSave={(blob) => console.log(blob)}
          render={({ canvas }) => (
            <div
              style={{
                alignContent: "center",
                textAlign: "center",
                justifyContent: "center",
                justifyItems: "center",
                height: height / 2,
              }}
            >
              {
                <div
                  style={{
                    zIndex: 999999,
                    height: height / 2,
                    border: "1px solid black",
                  }}
                  id={canvasID}
                  key={canvasID}
                >
                  {canvas}
                </div>
              }
            </div>
          )}
        />
      </div>
    );
  };

  const calculatorObject = () => {
    return (
      <div
        className="text-center"
        style={{ justifyContent: "center", width: "100%" }}
      >
        <Calculator />
      </div>
    );
  };
  if (!question?.question?.questionType || question.loading) {
    return (
      <div className="col-12" style={{ height: "100%" }}>
        <div className="row text-center" style={{ justifyContent: "center", height: "100%" }}>
          <div className="col-6" style={{ height: "100%", padding: "25px" }}>
            <Skeleton variant="text" sx={{ fontSize: "10rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

            {/* For other variants, adjust the size with `width` and `height` */}
            <Skeleton variant="rounded" width={"100px"} height={"50%"} />
          </div>
          <div className="col-6" style={{ height: "600px", padding: "25px" }}>
            <Skeleton variant="rounded" width={"100px"} height={"10%"} style={{float: "left", padding: "25px"}}/>
            <Skeleton variant="rounded" width={"100px"} height={"10%"} style={{float: "left", padding: "25px"}}/>
            <br /><br /><br />
            <Skeleton variant="rectangular" width={"100%"} height={"80%"} style={{padding: "10%"}} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="col-12">
        <div className="row text-center" style={{ justifyContent: "center" }}>
          <div className="col-6">
            <h3>{question?.question?.questionType}</h3>
            <img
              src={question?.question?.questionImage}
              alt={question?.question?.questionText}
              draggable="false"
              style={styleQuestionImage}
            />
            <hr
              style={{
                justifyContent: "center",
                marginLeft: "10%",
                marginRight: "10%",
              }}
            />
            <div
              className="row text-center"
              style={{ justifyContent: "center" }}
            >
              <div className="col-3">
                <QuestionButton
                  answer={question?.question?.answers[0]}
                  check_answer={question?.question?.checkAnswer}
                  key={0}
                  index={0}
                />
              </div>
              <div className="col-1"></div>
              <div className="col-2">
                <QuestionButton
                  answer={question.question.answers[1]}
                  check_answer={question.question.checkAnswer}
                  key={1}
                  index={1}
                />
              </div>
            </div>
            <div
              className="row text-center"
              style={{ justifyContent: "center" }}
            >
              <div className="col-3">
                <QuestionButton
                  answer={question.question.answers[2]}
                  check_answer={question.question.checkAnswer}
                  key={2}
                  index={2}
                />
              </div>
              <div className="col-1"></div>
              <div className="col-2">
                <QuestionButton
                  answer={question.question.answers[3]}
                  check_answer={question.question.checkAnswer}
                  key={3}
                  index={3}
                />
              </div>
            </div>
            <hr
              style={{
                justifyContent: "center",
                marginLeft: "10%",
                marginRight: "10%",
              }}
            />
          </div>

          <div
            className="col-6 text-center"
            style={{ justifyContent: "center", width: "100%", height: "100%" }}
          >
            <BottomNavigation showLabels>
              <BottomNavigationAction
                label="Whiteboard"
                onClick={() => setShowCanvas(true)}
                icon={<BorderColorIcon />}
              />
              {showCanvas && (
                <BottomNavigationAction
                  onClick={clearCanvas}
                  label="Clear"
                  icon={<ClearIcon />}
                />
              )}
              <BottomNavigationAction
                label="Calculator"
                onClick={() => setShowCanvas(false)}
                icon={<CalculateIcon />}
              />
            </BottomNavigation>
            <div
              style={
                showCanvas
                  ? { visibility: "visible", height: "100%" }
                  : { visibility: "hidden", height: 0 }
              }
            >
              {canvasObject()}
            </div>
            <div
              style={
                !showCanvas
                  ? { visibility: "visible", height: "100%", paddingTop: 20 }
                  : { visibility: "hidden", height: 0 }
              }
            >
              {calculatorObject()}
            </div>
          </div>
        </div>
        <div
          className="row text-center"
          style={{
            justifyContent: "center",
            marginLeft: "20%",
            marginRight: "20%",
            paddingTop: 25,
          }}
        >
          <LinearProgress
            determinate
            variant="outlined"
            color="success"
            size="sm"
            thickness={24}
            value={Number(value)}
            sx={{
              "--LinearProgress-radius": "20px",
              "--LinearProgress-thickness": "24px",
            }}
          >
            <Typography
              level="body-xs"
              fontWeight="xl"
              textColor="common.white"
              sx={{ mixBlendMode: "difference" }}
            >
              {question.question.questionType} | Progress{" "}
              {`${Math.round(Number(value))}%`}
            </Typography>
          </LinearProgress>
        </div>
      </div>
    );
  }
}

const styleQuestionImage = {
  maxWidth: "100%",
  height: "auto",
  display: "block",
  margin: "0 auto" /* Optional: center the image */,
  pointerEvents: "none",
};

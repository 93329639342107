import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import DesignServices from "@mui/icons-material/DesignServices";
import EditCalendar from "@mui/icons-material/EditCalendar";
import Edit from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import Check from "@mui/icons-material/Check";
import StepButton from "@mui/joy/StepButton";
import useIsMobile from "./useIsMobile";
import { AuthContext } from "./AuthContext";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const steps = ["Validate Email", "Premium Acccount", "Pass Exam"];
const Dashboard = () => {
  const { handleAccess, gatherProfile } = useContext(AuthContext);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null);
  useEffect(() => {
    handleAccess();
    gatherProfile().then((profile) => {
      const progress = profile?.progress || 0;
      const remainingQuestion = 1 - (profile?.questionCount || 0);
      setUserProfile((prev) => ({
        ...prev,
        isValidated: profile?.isEmailValidated,
        isPremiumUser: profile?.isPremiumUser,
        remainingQuestion: remainingQuestion >= 0 ? remainingQuestion : 0,
        progress: progress >= 0 ? progress : 0,
      }));
    });
  }, [handleAccess, gatherProfile]);
  const activeStep =
    userProfile?.isValidated &&
    userProfile?.isPremiumUser &&
    userProfile?.progress === 100
      ? 3
      : userProfile?.isValidated && userProfile?.isPremiumUser
      ? 2
      : userProfile?.isValidated
      ? 1
      : 0;
  return (
    <>
      <Navbar />
      <Container
        maxWidth="lg"
        sx={{
          height: "90vh", // Set the height to 100% of the viewport height
        }}
      >
        <Box
          my={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              display: "flex",
              minWidth: 310,
              maxWidth: 1200,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!userProfile?.isValidated ? (
              <Alert severity="warning">
                Please confirm your email to allow password reset and start your
                premium account!
              </Alert>
            ) : (
              !userProfile?.isPremiumUser && (
                <Alert severity="primary">
                  With a premium account you will have unlimited questions,
                  timed exams and quizzes to learn in a more realistic
                  environment.
                </Alert>
              )
            )}
            <Tabs
              orientation="horizontal"
              size="lg"
              defaultValue={0}
              style={{ background: "white" }}
            >
              <TabList style={{ margin: "auto" }}>
                <Tab variant="outlined" color="neutral" default>
                  Question {!isMobile && <Edit />}
                </Tab>
                <Tab
                  variant="outlined"
                  color="neutral"
                  disabled={!userProfile?.isPremiumUser}
                >
                  Exam {!isMobile && <EditCalendar />}
                </Tab>
                <Tab
                  variant="outlined"
                  color="neutral"
                  disabled={!userProfile?.isPremiumUser}
                >
                  Quiz {!isMobile && <DesignServices />}
                </Tab>
              </TabList>
              <TabPanel value={0} style={{ backgroundColor: "#fff" }}>
                <h2>
                  <Edit />
                  &nbsp;Practice Questions
                </h2>
                <Typography>
                  Complete a Practice Question, free users only get three
                  problems per month. Paid users have infinite questions!
                </Typography>
                <br />
                <br />
                <div className="row" style={{ justifyContent: "center" }}>
                  <div
                    className="row"
                    style={{
                      maxWidth: "100px",
                      margin: "auto",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgressbar
                      color={
                        userProfile?.progress === 100 ? "success" : "primary"
                      }
                      value={userProfile?.progress}
                      maxValue={100}
                      text={`${userProfile?.progress}%`}
                    />
                    <hr />
                  </div>
                </div>
                <br />
                <br />
                <div className="row" style={{ justifyContent: "center" }}>
                  <div className="col-4">
                    <div
                      className="row"
                      style={{ width: "100%", margin: "auto" }}
                    >
                      <div className="col-6">
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => {
                            navigate("/p-question");
                          }}
                          disabled={
                            userProfile?.remainingQuestion < 1 &&
                            !userProfile?.isPremiumUser
                          }
                        >
                          Start Question
                        </Button>
                      </div>
                      <div className="col-6">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            navigate("/p-question-history");
                          }}
                        >
                          Question History
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                {!userProfile?.isPremiumUser && (
                  <div style={{ paddingTop: 10, textAlign: "center" }}>
                    <Typography>
                      Remaining Questions: {userProfile?.remainingQuestion}
                    </Typography>
                  </div>
                )}
              </TabPanel>

              <TabPanel value={1} style={{ backgroundColor: "#fff" }}>
                <h2>
                  <EditCalendar />
                  &nbsp;Practice Exams
                </h2>
                <Typography>
                  Complete a practice Exam, 30 questions within 3 hours to
                  simulate the real exam.
                </Typography>
                <br />
                <br />
                <div className="row" style={{ justifyContent: "center" }}>
                  <div
                    className="row"
                    style={{
                      maxWidth: "100px",
                      margin: "auto",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgressbar
                      color={
                        userProfile?.progress === 100 ? "success" : "primary"
                      }
                      value={userProfile?.progress}
                      maxValue={100}
                      text={`${userProfile?.progress}%`}
                    />
                    <hr />
                  </div>
                </div>
                <br />
                <br />
                <div className="row" style={{ justifyContent: "center" }}>
                  <div className="col-4">
                    <div
                      className="row"
                      style={{ width: "100%", margin: "auto" }}
                    >
                      <div className="col-6">
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => {
                            navigate("/p-exam");
                          }}
                        >
                          Start Exam
                        </Button>
                      </div>
                      <div className="col-6">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            navigate("/p-exam-history");
                          }}
                        >
                          Exam History
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={2} style={{ backgroundColor: "#fff" }}>
                <h2>
                  <DesignServices />
                  &nbsp;Practice Quiz
                </h2>
                <Typography>
                  Complete a practice Quiz, 5 questions within 30 minutes to
                  gain knowledge.
                </Typography>

                <br />
                <br />
                <div className="row" style={{ justifyContent: "center" }}>
                  <div
                    className="row"
                    style={{
                      maxWidth: "100px",
                      margin: "auto",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgressbar
                      color={
                        userProfile?.progress === 100 ? "success" : "primary"
                      }
                      value={userProfile?.progress}
                      maxValue={100}
                      text={`${userProfile?.progress}%`}
                    />
                    <hr />
                  </div>
                </div>
                <br />
                <br />
                <div className="row" style={{ justifyContent: "center" }}>
                  <div className="col-4">
                    <div
                      className="row"
                      style={{ width: "100%", margin: "auto" }}
                    >
                      <div className="col-6">
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => {
                            navigate("/p-quiz");
                          }}
                        >
                          Start Quiz
                        </Button>
                      </div>
                      <div className="col-6">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            navigate("/p-quiz-history");
                          }}
                        >
                          Quiz History
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>

            <div
              className="row"
              style={{
                maxWidth: "600px",
                margin: "auto",
                justifyContent: "center",
              }}
            >
              <Stepper sx={{ width: "100%", maxWidth: 600 }}>
                {steps.map((step, index) => (
                  <Step
                    key={step}
                    indicator={
                      <StepIndicator
                        variant={"soft"}
                        color={activeStep <= index ? "danger" : "primary"}
                      >
                        {activeStep <= index ? index + 1 : <Check />}
                      </StepIndicator>
                    }
                    sx={{
                      "&::after": {
                        ...(activeStep > index && {
                          bgcolor: "primary.solidBg",
                        }),
                      },
                    }}
                  >
                    <StepButton onClick={() => {}}>{step}</StepButton>
                  </Step>
                ))}
              </Stepper>
              <br />
              <br />
              {userProfile?.progress ? (
                <div className="alert alert-success">
                  Congratulations! You've answered every possible question
                  correctly at least once. Now you are ready for the real exam!
                </div>
              ) : (
                <></>
              )}
              <br />
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default Dashboard;
